import { createTheme, ThemeProvider } from '@mui/material/styles';

import VendorForm from "./pages/VendorForm";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(50,100,150)",
    },
    secondary: {
      main: "rgb(0,0,0)"
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <VendorForm />
    </ThemeProvider>
  );
}

export default App;
