import React from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/RemoveCircleOutline";
import PropTypes from "prop-types";

const VendorFormRow = ({ data, onUpdate, onDelete }) => {
  return (
    <Container>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        label="Franchise"
        value={data.franchise}
        onChange={(e) => onUpdate({ ...data, franchise: e.target.value })}
        style={{ margin: "10px 0" }}
        InputLabelProps={{ shrink: true }}
        inputProps={{maxLength: 100}}
      />
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        label="Character"
        value={data.character}
        onChange={(e) => onUpdate({ ...data, character: e.target.value })}
        style={{ margin: "10px 0 10px 10px" }}
        InputLabelProps={{ shrink: true }}
        inputProps={{maxLength: 100 }}
      />
      <TextField
        size="small"
        type="number"
        variant="outlined"
        label="Min # Units"
        value={data.quantity}
        onChange={(e) => onUpdate({ ...data, quantity: e.target.value })}
        style={{ margin: "10px", minWidth: "90px" }}
      />
      <CheckboxContainer>
        <Checkbox
          checked={data.sendin}
          style={{ padding: "0px" }}
          color="primary"
          onChange={(e) => onUpdate({ ...data, sendin: e.target.checked })}
        />
        <CheckboxLabelContainer>
          <CheckboxLabel>Sendin</CheckboxLabel>
        </CheckboxLabelContainer>
      </CheckboxContainer>
      <DeleteButton onClick={() => onDelete(data.id)}>
        <DeleteIcon />
      </DeleteButton>
    </Container>
  );
};

VendorFormRow.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

export default VendorFormRow;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DeleteButton = styled.div`
  color: rgb(255, 100, 100);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: rgb(255, 150, 150);
  }
  &:active {
    color: rgb(255, 200, 200);
  }
  @media only screen and (min-width: 500px) {
    margin: 0 10px;
    margin-left: 15px;
  }
  margin-left: 5px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CheckboxLabelContainer = styled.div`
  position: relative;
`;

const CheckboxLabel = styled.div`
  font-size: 11px;
`;
