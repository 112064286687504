import React, { useState, useRef, useCallback } from "react";
import axios from "axios";
import styled from "styled-components";
import VendorFormRow from "./VendorFormRow";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const VendorForm = () => {
  const INIT_ROWS_DATA = [
    {
      id: 0,
      franchise: "",
      character: "",
      quantity: 0,
      sendin: false
    },
    {
      id: 1,
      franchise: "",
      character: "",
      quantity: 0,
      sendin: false
    },
    {
      id: 2,
      franchise: "",
      character: "",
      quantity: 0,
      sendin: false
    },
  ];

  const idCounter = useRef(INIT_ROWS_DATA.length);

  const AnchorRef = useRef(null);
  const SubmitButtonRef = useRef(null);

  const [rows, setRows] = useState(INIT_ROWS_DATA);
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [website, setWebsite] = useState("");
  const [sendinHubOptin, setSendinHubOptin] = useState(false);
  const [sellOurStockOptin, setSellOurStockOptin] = useState(false);

  const Submit = useCallback(async () => {
    try {
      setErrorText("");
      setIsLoading(true);
      await axios.post(`${process.env.REACT_APP_API_URL}/vendorportal/form`, {
        name,
        email,
        phone_number: phoneNumber,
        website,
        sendin_hub_optin: sendinHubOptin,
        sell_our_stock_optin: sellOurStockOptin,
        products: rows,
      });
      setIsSuccess(true);
      if (AnchorRef.current) AnchorRef.current.scrollIntoView();
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        setErrorText(error.response.data);
      } else {
        setErrorText("Something went wrong. Please try again.");
      }
      if (SubmitButtonRef.current) SubmitButtonRef.current.scrollIntoView();
    } finally {
      setIsLoading(false);
    }
  }, [name, email, phoneNumber, website, sendinHubOptin, sellOurStockOptin, rows]);

  const AddRow = () => {
    setErrorText("");
    setRows((prevState) => [
      ...prevState,
      {
        id: idCounter.current++,
        franchise: "",
        character: "",
        quantity: 0,
        sendin: false
      },
    ]);
    if (SubmitButtonRef.current) SubmitButtonRef.current.scrollIntoView();
  };

  const OnFormRowUpdate = (newData) => {
    setErrorText("");
    setRows((prevState) =>
      prevState.map((item) => (item.id === newData.id ? newData : item)),
    );
  };

  const OnFormRowDelete = (id) => {
    setErrorText("");
    setRows((prevState) => prevState.filter((item) => item.id !== id));
  };

  const ResetForm = () => {
    setErrorText("");
    setIsSuccess(false);
    setRows(INIT_ROWS_DATA);
    if (AnchorRef.current) AnchorRef.current.scrollIntoView();
  };

  return (
    <Container>
      <Anchor ref={AnchorRef} />
      <Title>Streamily Vendor Order Form</Title>
      <Subtitle>
        Please fill out the form below to request for your products to be signed
        by any of our many actors.
      </Subtitle>
      {isSuccess ? (
        <SuccessContainer>
          <SuccessTitle>Your order has been submitted</SuccessTitle>
          <Button
            variant="outlined"
            style={{ margin: "10px 0" }}
            onClick={ResetForm}
          >
            Fill Out Another Form
          </Button>
        </SuccessContainer>
      ) : (
        <React.Fragment>
          <SectionTitle>Contact Info</SectionTitle>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            label="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ margin: "10px 0" }}
            inputProps={{maxLength: 100}}
          />
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            label="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ margin: "10px 0" }}
            inputProps={{maxLength: 100}}
          />
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            label="Your Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={{ margin: "10px 0" }}
            inputProps={{maxLength: 100}}
          />
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            label="Your Website (optional)"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            style={{ margin: "10px 0" }}
            inputProps={{maxLength: 100}}
          />
          <CheckboxesContainer>
            <FormControlLabel 
              label="Would you like to be a hub for sendins?"
              style={{color: "rgb(90,90,90)"}}
              control={
                <Checkbox
                  checked={sendinHubOptin}
                  color="primary"
                  onChange={(e) => setSendinHubOptin(e.target.checked)}
                />
              }
            />
            <FormControlLabel 
              label="Would you like to sell our stock?"
              style={{color: "rgb(90,90,90)"}}
              control={
                <Checkbox
                  checked={sellOurStockOptin}
                  color="primary"
                  onChange={(e) => setSellOurStockOptin(e.target.checked)}
                />
              }
            />
          </CheckboxesContainer>
          <SectionTitle>Products</SectionTitle>
          <Subtitle>
            Please provide the franchise and character that you would like to sell product of,
            the minimum number of units you'd like to order, and whether or not these products
            are "Sendins". Mark "Sendin" if the products will be provided by your customers, and not you.
            <br/><br />Example: 10 units of Sasuke from Naruto, not "Sendins".
          </Subtitle>
          {rows.map((item) => (
            <VendorFormRow
              key={`vendorFormRow-${item.id}`}
              data={item}
              onUpdate={OnFormRowUpdate}
              onDelete={OnFormRowDelete}
            />
          ))}
          <Button
            variant="outlined"
            style={{ margin: "10px 0" }}
            onClick={AddRow}
          >
            <AddIcon style={{ marginRight: "5px", fontSize: "20px" }} />
            Add Row
          </Button>
          {errorText && <ErrorText>{errorText}</ErrorText>}
          <Button
            ref={SubmitButtonRef}
            variant="contained"
            color="primary"
            fullWidth
            style={{ margin: "10px 0" }}
            onClick={Submit}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
        </React.Fragment>
      )}
    </Container>
  );
};

export default VendorForm;

const Container = styled.div`
  padding: 20px;
  @media only screen and (max-width: 700px) {
    padding: 10px;
  }
  min-height: 90vh;
  position: relative;
  max-width: 1000px;
`;

const Anchor = styled.div`
  position: absolute;
  top: -50px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 0px;
  color: rgb(60,60,60);
`;

const SectionTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  color: rgb(60,60,60);
`;

const Subtitle = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
  max-width: 500px;
  color: rgb(90,90,90);
`;

const ErrorText = styled.div`
  font-size: 14px;
  color: red;
  margin: 10px;
  text-align: center;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vh;
`;

const SuccessTitle = styled.div`
  font-size: 20px;
  color: rgb(100, 100, 100);
`;

const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
